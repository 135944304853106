import { Permission } from '@campxdev/shared'
import { EvaluationEditor, StudentScriptView } from 'pages'

export const editorRoutes = [
  {
    index: true,
    element: <EvaluationEditor />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
]

export const scriptViewRoutes = [
  {
    index: true,
    element: <StudentScriptView />,
  },

  {
    path: 'script/:scriptCode',
    element: <StudentScriptView />,
  },
]
