import { ErrorBoundary } from '@campxdev/shared'
import { Box } from '@mui/material'
import Tabs from 'components/Tabs/Tabs'
import { Outlet } from 'react-router-dom'
import { EvaluatorStore } from 'shared-state/UserStore'

export default function DashboardLayout() {
  const EvaluatorState = EvaluatorStore.useState((s) => s)
  const tabs = [
    { path: '/digital/bundles-assigned', label: 'Digital Evaluations' },
    { path: '/manual/bundles', label: 'Physical Evaluation' },
    ...(EvaluatorState.evaluator.isAdmin
      ? [{ path: '/exams-view', label: 'Exams' }]
      : []),
  ]
  return (
    <>
      <Box>
        <Tabs tabs={tabs} />
      </Box>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </>
  )
}
