import { PageNotFound, Permission } from '@campxdev/shared'
import { BundleView, BundlesAssigned, ExamsView } from 'pages'

import Bundles from 'pages/PhysicalEvaluation'
import { BundleMarksView } from 'pages/PhysicalEvaluation/BundleMarksView'
import { Navigate } from 'react-router-dom'

export const baseRoutes = [
  {
    index: true,
    element: <Navigate to="digital" />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'digital',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
    children: [
      {
        index: true,
        element: <Navigate to="bundles-assigned" />,
        permissionKey: Permission.CAN_DASHBOARD_VIEW,
      },
      {
        path: 'bundles-assigned',
        element: <BundlesAssigned />,
        permissionKey: Permission.CAN_DASHBOARD_VIEW,
      },
      {
        path: 'bundles-assigned/:bundleId',
        element: <BundleView />,
        permissionKey: Permission.CAN_DASHBOARD_VIEW,
      },
    ],
  },
  {
    path: 'manual/bundles',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
    element: <Bundles />,
    children: [
      {
        path: ':bundleId',
        element: <BundleMarksView />,
        permissionKey: Permission.CAN_DASHBOARD_VIEW,
      },
    ],
  },
  {
    path: 'exams-view',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
    element: <ExamsView />,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]
